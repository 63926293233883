import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import Login from './pages/admin/login';
import ProtectedRoute from './services/LoginCheck';
import Dashboard from './pages/admin/Dashboard';
import AdminPresenters from './pages/admin/Presenters';
import AdminRules from './pages/admin/Rules';
import AdminVenue from './pages/admin/Venue';
import Tickets from './pages/tickets';
import SurvivePage from './pages/survive';

const Home = lazy(() => import('./pages/home'));
const About = lazy(() => import('./pages/about'));
const Presenters = lazy(() => import('./pages/presenters'));
const Schedule = lazy(() => import('./pages/schedule'));
const Venue = lazy(() => import('./pages/venue'));
const Rules = lazy(() => import('./pages/rules'));
const PrevFestival = lazy(() => import('./pages/prevFestival'));
const Contact = lazy(() => import('./pages/contact'));


const routes: RouteObject[] = [
    {
        path: 'about',
        element: <About />,
    },
    {
        path: 'presenters',
        element: <Presenters />,
    },
    {
        path: 'schedule',
        element: <Schedule />,
    },
    {
        path: 'venue',
        element: <Venue />,
    },
    {
        path: 'survive',
        element: <SurvivePage />,
    },
    {
        path: 'rules',
        element: <Rules />,
    },
    {
        path: 'previous',
        element: <PrevFestival />,
    },
    {
        path: 'contact',
        element: <Contact />,
    },
    {
        path: 'tickets',
        element: <Tickets />,
    },
    {
        path: 'admin/6XB64QhE1L4xneYrmBtm',
        element: <Login />,
    },
    {
        path: 'admin/fNhzjfq1Jrd8GgkQ6w3x',
        element: <ProtectedRoute component={Dashboard} />,
    },
    {
        path: 'admin/DZkTnw8f95Xd6Z6nzRqf',
        element: <ProtectedRoute component={AdminPresenters} />,
    },
    {
        path: 'admin/4jycdWB246yTUofSRD3W',
        element: <ProtectedRoute component={AdminRules} />,
    },
    {
        path: 'admin/V2bDwNXhgYF43CgMRyda',
        element: <ProtectedRoute component={AdminVenue} />,
    },
    {
        path: '*',
        index: true,
        element: <Home />
    },

];

export default routes;
