import Navbar from "../components/Nav/Navbar";

function SurvivePage() {
    return (
        <>
            <Navbar />
            <main className="mx-8 overscroll-contain select-none">
                <div className="text-4xl font-bold underline flex flex-col justify-center text-center lg:text-7xl">
                    <h1>How to survive the Festival</h1>
                </div>
                <ul className="flex flex-col justify-center list-disc list-outside font-sans font-medium mt-5 space-y-4 mb-14 text-2xl mx-7 lg:mx-80 lg:text-4xl">
                    <li>Make sure you are rested when you arrive, you will lack sleep during the event.</li>
                    <li>Make sure to drink and eat enough during the event.</li>
                    <li>Make sure you take breaks, try to retreat either in your own tent/caravan or in the lounge/quiet area.</li>
                    <li>Read the <a href="schedule" className="text-blue-800 underline hover:text-blue-600">schedule</a> for the event.</li>
                    <li>Plan in advance which workshops you want to participate in, if you have energy for more than planned, you can always upgrade. Never plan to attend everything - you will burn out quickly.</li>
                    <li>Check if others you know are also going to the event. It will make you more comfortable if you have someone to talk to from the first day.</li>
                    <li>If possible, talk to previous participants about their experiences from the event.</li>
                    <li>If you become mentally overloaded (most people do, attending for the first time), and need support find out if there is someone at the event you can talk to or contact the organizer, we would love to support you.</li>
                    <li>Bring what makes you comfortable and allows you to relax, whether it's headphones, a blanket, a cuddly toy.</li>
                    <li>After the event take into account that you can get a drop, therefore have someone you can talk to.</li>
                    <li>See pictures from <a href="/previous" className="text-blue-800 underline hover:text-blue-600">previous editions</a> to get an idea of the place.</li>
                    <li>Find out what the <a href="/rules" className="text-blue-800 underline hover:text-blue-600">rules of the venue</a> are.</li>
                </ul>
            </main>
        </>
    )
}

export default SurvivePage;